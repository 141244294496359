<template>
	<div class="app-container">
		<!-- filter -->
		<div class="filter-container">
			<!-- 关键字搜索 -->
			<div class="filter-item">
				<label class="label">关键字: </label>
				<el-input v-model="searchKey"  placeholder="活动名称"></el-input>
			</div>
			<!--优惠券类型  -->
			<div class="filter-item">
				<label class="label">活动状态: </label>
				<el-select v-model="type" placeholder="请选择">
					<el-option label="全部" :value="null"></el-option>
					<el-option v-for="item in typeOptions" :key="item.value" :label="item.lable" :value="item.value"></el-option>
				</el-select>
			</div>
			<!-- button -->
			<div class="filter-item">
				<el-button type="primary" style="margin-left:30px;width:90px;" @click="handleFilter">查询</el-button>
				<button-permissions :datas="'addVoucher'">
					<el-button type="primary" style="margin-left:30px;width:100px;" @click="handleTableEdit(1,0,1)" >添加</el-button>
				</button-permissions>
			</div>
		</div>
		<!-- table -->
		<div class="table-container">
			<el-table	:data="tableData"	style="width: 100%" v-loading="loading">
				<el-table-column	prop="Name"	label="活动名称"></el-table-column>
				<el-table-column	prop="UseTypeValue"	label="活动时间">
					<template slot-scope='scope'>
						<span>{{scope.row.StartTime}}</span>
						<span style='margin:0 5px;'>至</span>
						<span>{{scope.row.EndTime}}</span>
          </template>
				</el-table-column>
				<el-table-column  prop="ActivityStateShow" label="状态"></el-table-column>
				<el-table-column	prop="LuckyDrawCount"	label="抽奖人数"></el-table-column>
				<el-table-column	prop="WinnerCount"	label="中奖人数">
          <template slot-scope='scope'>
            <el-button v-if='scope.row.WinnerCount!=0' type='text' @click='popWinsDia(scope.row)'>{{scope.row.WinnerCount}}</el-button>
            <span v-else>0</span>
          </template>
        </el-table-column>
				<el-table-column	prop="AddTime"	label="创建时间"></el-table-column>
				<el-table-column	label="操作" width='180px'>
					<template slot-scope="scope" >
						<div   v-if="scope.row.ActivityState!=3" >
							<button-permissions :datas="'editLottery'">
								<div class="table-button" @click="handleTableEdit(2,scope.row.Id,scope.row.ActivityState)">编辑</div>
							</button-permissions>

							<div class="table-button" @click="handleTablePromote(scope.row.Id)">推广</div>

							<el-dropdown v-show='showFinishBtn||showCopyBtn'>
								<el-button  type="text">
									更多
								</el-button>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item >
										<button-permissions :datas="'finishLottery'"  @isShow='isShowFinish'>
											<div class="table-button" @click="handleTableFail(scope.row.Id)">使终止</div>
										</button-permissions>
									</el-dropdown-item>
									<el-dropdown-item>
										<button-permissions :datas="'copyLottery'" @isShow='isShowCopy'>
											<div class="table-button" @click="handleTableEdit(4,scope.row.Id,1)">复制</div>
										</button-permissions>
									</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>

						</div>

						<div v-else>

							<div class="table-button" @click="handleTableEdit(3,scope.row.Id,scope.row.ActivityState)">查看</div>

							<button-permissions :datas="'deleteLottery'" >
								<div class="table-button" style="color:#F56C6C"  @click="handleTableDelete(scope.row.Id)">删除</div>
							</button-permissions>

							<button-permissions :datas="'copyLottery'" @isShow='isShowCopy'>
								<div class="table-button" @click="handleTableEdit(4,scope.row.Id,1)">复制</div>
							</button-permissions>

						</div>

					</template>
				</el-table-column>
			</el-table>
			<el-pagination v-if="page.total" style="margin-top:20px;float:right;" @size-change="handleSizeChange" @current-change="handleCurrentChange"
			 :current-page="page.current" :page-sizes="[10, 20, 30, 40, 50]" :page-size="page.size" layout="total, sizes, prev, pager, next, jumper"
			 :total="page.total">
			</el-pagination>
		</div>

		<el-dialog title="中奖明细" :visible.sync="winnersListShow"  v-if="winnersListShow" width="800px" style='overflow:hidden;' class="dialog data-dialog">
				<div style='width:100%;overflow:hidden;'>
					<div style='margin-bottom:20px;'>
						<span style='margin-right:50px;'>中奖人数：{{currentWins.Number}}人</span>
						<span>已发放奖品：{{currentWins.Count}}</span>
					</div>
					<el-table	:data="winnersList"	style="width: 100%;" v-loading="winsLoading" max-height='500px'>
						<el-table-column	prop="MemberName"	label="客户">
							<template slot-scope='scope'>
								<div style='width:200px;text-overflow:ellipsis;white-space:nowrap;overflow:hidden;'>
									{{scope.row.MemberName}}
								</div>
							</template>
						</el-table-column>
						<el-table-column	prop="PrizeTypeName"	label="奖品类型"></el-table-column>
						<el-table-column	prop="PrizeName"	label="奖品"></el-table-column>
						<el-table-column	prop="AddTime"	label="中奖时间"></el-table-column>
					</el-table>
					<el-pagination v-if="winsPage.total" style="margin-top:20px;float:right;" @size-change="handleWinsSizeChange" @current-change="handleWinsCurrentChange"
						:current-page="winsPage.current" :page-sizes="[10, 20, 30, 40, 50]" :page-size="winsPage.size" layout="total, sizes, prev, pager, next, jumper"
						:total="winsPage.total">
					</el-pagination>
				</div>
		</el-dialog>

		<!-- 推广的弹框 -->
		<batch-cods  :weiCodeVisivble="weiCodeShow" :weiCodeData="weiCodeData"  @cancel="weiCodeCancel"></batch-cods>
	</div>
</template>

<script>
import buttonPermissions from '@/components/buttonPermissions';
import batchCods from "@/components/bathCodes"
import apiList	from '@/api/other'
	export default {
		components: {
			buttonPermissions,batchCods
		},
		data(){
			return{
				weiCodeData:{},
				isMoreShow:false,
				weiCodeShow:false,
				bathSortDatas: [],
				searchKey:'',
				type:null,
				loading: false,
				typeOptions:[
					{
						lable:'未开始',value:0
					},
					{
						lable:'进行中',value:1
					},
					{
						lable:'已结束',value:2,
					}
				],
				tableData:[
				],
				page:{
					total:0,
					current:1,
					size:20
				},
				currentEditId:null,
				winsLoading:false,
				winnersList:[],
				winnersListShow:false,
				currentWins:{
					Id:0,
					Number:0,
					Count:0
				},
				winsPage:{
					total:0,
					current:1,
					size:10
				},
				showCopyBtn:true,
				showFinishBtn:true,
			}
		},
		created(){
			this.getList()
		},
    methods: {
			// 获取列表数据
			async getList() {
				this.loading = true;
				try {
					let data = {
						KeyWord :this.searchKey,
						ActivityState : this.type,
						Skip: (this.page.current - 1) * this.page.size, 
						Take: this.page.size, 
					}
					let result = await apiList.activityWheelSurfList(data);
					this.page.total = result.Result.Total;
					this.tableData = result.Result.Results || [];

					this.tableData.forEach((t)=>{
						t.ActivityState=t.ActivityStateShow=='未开始'?1:(t.ActivityStateShow=='进行中'?2:3)
					})
				} catch (error) {
					console.log(error);
					this.loading = false
				} finally {
						this.loading = false;
				}
			},
			handleFilter(){
				this.page.size = 20
				this.page.current = 1
				this.getList()
			},
			//type---1:新增;2:编辑;3:查看;4:复制
			//status---1:未开始;2:进行中;3:已结束
			handleTableEdit(type,id,status){
				this.$router.push({ 
          path: '/market/lottery/editlotteryDetail',
          query:{
						type:type,
						id:id,
						status:status
          }
        })
			},
			//使失效
			handleTableFail(id){
				this.$confirm('活动终止后，你将不可再对这场活动进行编辑，是否确认使终止？', '提示', {
					confirmButtonText: '确认终止',
					cancelButtonText: '关闭',
					type: 'warning'
				}).then(async() => {
					let result = await apiList.activityWheelSurfOpen({
						Id: id
					})
					this.$message({showClose: true,
						type: 'success',
						message: '终止成功!'
					});
					this.getList()

				}).catch(() => {
					this.$message({showClose: true,
						type: 'info',
						message: '已取消终止'
					});
				}).finally(() => {
					// this.getList()
				})
			},
				// table删除
			handleTableDelete(id){
				this.$confirm('是否确认删除本场活动?删除后不可恢复', '提示', {
					confirmButtonText: '确认删除',
					cancelButtonText: '关闭',
					type: 'warning'
				}).then(async() => {
					try{
						let result = await apiList.activityWheelSurfDel({
							Id: id
						})
						this.$message({showClose: true,
							type: 'success',
							message: '删除成功!'
						});

						let currentPageTotal = this.page.total - (this.page.current - 1) * this.page.size
						if(currentPageTotal == 1 && this.page.current!=1){
							this.page.current -= 1
						}
						this.getList()

					} catch(e) {
						console.log("e",e)
						return 
					} finally{

					}

				}).catch(() => {
					this.$message({showClose: true,
						type: 'info',
						message: '已取消删除'
					});
				}).finally(() => {
					// this.getList();
				})
			},
			getRamNumber(){
				var result='';
				for(var i=0;i<6;i++){
				
					result+=Math.floor(Math.random()*16).toString(16);
				}
				
				return result;
			},
			// 推广-优惠券
			handleTablePromote(id){
				this.weiCodeShow = true;
				let uid =this.getRamNumber()
				this.weiCodeData = {
					Page:'pageA/pages/activity/truntable/truntable',
					Scene:`id=${id}`
				}
			},
			weiCodeCancel(val) {
				this.weiCodeShow = false;
			},
			// 切换显示条数
			handleSizeChange(val) {
				this.page.size = val;
				this.getList();
			},
			// 翻页
			handleCurrentChange(val) {
				this.page.current = val;
				this.getList();
			},
			async getWinsList(){
				this.winsLoading = true;
				try {
					let result = await apiList.activityWheelSurfDetail(
						{
							Id:this.currentWins.Id,
							Skip: (this.winsPage.current - 1) * this.winsPage.size, 
							Take: this.winsPage.size, 
						}
					);
					this.winsPage.total = result.Result.Total;
					this.winnersList = result.Result.Results || [];
				} catch (error) {
					console.log(error);
					this.winsLoading = false
				} finally {
					this.winsLoading = false;
				}
			},
			popWinsDia(val){
				this.currentWins = {
					Id:val.Id,
					Number:val.WinnerCount,
					Count:val.WinningNumber
				}
				this.winnersListShow=true
				this.getWinsList()
			},
			// 切换显示条数
			handleWinsSizeChange(val) {
				this.winsPage.size = val;
				this.getWinsList();
			},
			// 翻页
			handleWinsCurrentChange(val) {
				this.winsPage.current = val;
				this.getWinsList();
			},
			isShowCopy(val){
				this.showCopyBtn = val
			},
			isShowFinish(val){
				this.showFinishBtn = val
			}
    }
	}
</script>

<style  lang="less" scoped>
.app-container{
	/deep/.el-input{
		width: 220px;
	}
	background-color: #fff;
}
	.table-button{
		color: #409EFF;
		display: inline-block;
		margin-right: 15px;
		cursor: pointer;
	}
	.dialog{
		width: 100%;
		/deep/.el-dialog__header {
				border-bottom: 1px solid #ddd;
		}
		.content{
			padding: 0 30px;
			display: flex;
			flex-direction: row;
			.right{
				margin-left: 20px;
			}
			.text{
				margin-left: 20px;
				font-size: 14px;
				color: #999;
				line-height: 40px;
			}
		}
		.button{
			margin: 0 auto;
			width: 200px;
		}
	}
	.data-dialog{
		.value{
			line-height: 50px;
			font-size: 24px;
			color: #000;
		}
		.item{
			color: #666;
			margin-top: 10px;
		}
		overflow:hidden;
	}
	.dialog-footer{
		margin-top:30px;
		text-align: center;
	}
	.el-pagination{
		/deep/.el-input{
			width:100px;
		}
	}
</style>
